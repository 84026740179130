import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import GlobalStyles from './GlobalStyles';
import Ebooks from './pages/Ebooks';
import VideoTraining from './pages/VideoTraining';
import Aprofundamento from './pages/Aprofundamento';
import MouseTrap from './pages/MouseTrap';
import DataView from './components/DataView';
import Community from './pages/Community';
import { initGA, logPageView } from './utils/analytics';
import LoggedRedirect from './components/LoggedRedirect';

const App = () => {
  useEffect(() => {
    initGA();
    logPageView();
    window.addEventListener('popstate', logPageView);
  }, []);

  return (
    <Router>
      <GlobalStyles />
      <RouteChangeTracker />
      <Switch>
        <Route path="/" exact component={Home} />

        {/* Sub-rotas para "faça-parte" */}
        <Route path="/faça-parte" exact component={Community} />
        <Route
          path="/faça-parte/voluntario"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />
        <Route
          path="/faça-parte/iniciacao"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />
        <Route
          path="/faça-parte/doe"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />

        {/* Sub-rotas para "aprofundamento" */}
        <Route path="/aprofundamento" exact component={Aprofundamento} />
        <Route
          path="/aprofundamento/mentoria"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />
        <Route
          path="/aprofundamento/sonhos"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />
        <Route
          path="/aprofundamento/desenhos-e-pinturas"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />
        <Route
          path="/aprofundamento/analise-de-perfil"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />

        {/* Sub-rotas para "treinamentos" */}
        <Route path="/treinamentos" exact component={VideoTraining} />

        {/* Sub-rotas para "livros" */}
        <Route path="/ebooks" exact component={Ebooks} />
        <Route
          path="/ebooks/alchemy"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />
        <Route
          path="/ebooks/occultism"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />
        <Route
          path="/ebooks/ancient-wisdom"
          render={(props) => (
            <LoggedRedirect to="/eu-quero" path={props.location.pathname} />
          )}
        />

        <Route path="/data-view" exact component={DataView} />
        <Route path="/eu-quero" exact component={MouseTrap} />
      </Switch>
    </Router>
  );
};

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
    // Store navigation path in local storage
    const currentPath = location.pathname;
    let navigationHistory = JSON.parse(localStorage.getItem('navigationHistory')) || [];
    navigationHistory.push(currentPath);
    localStorage.setItem('navigationHistory', JSON.stringify(navigationHistory));
  }, [location]);

  return null;
};

export default App;
