// src/utils/analytics.js
import ReactGA from 'react-ga';

export const initGA = () => {
  console.log("Initializing Google Analytics"); // Debugging log
  ReactGA.initialize('G-8DTH9SJQRP'); // Replace with your Google Analytics tracking ID
  console.log("Google Analytics initialized with ID: G-8DTH9SJQRP"); // Debugging log
};

export const logPageView = () => {
  console.log("Logging page view: " + window.location.pathname + window.location.search); // Debugging log
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const logEvent = (category, action, label) => {
  console.log(`Logging event - Category: ${category}, Action: ${action}, Label: ${label}`); // Debugging log
  ReactGA.event({ category, action, label });
};
