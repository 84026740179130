import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import InputMask from 'react-input-mask';

const Home = () => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [whatsapp, setWhatsapp] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handlePlayButtonClick = () => {
    setVideoVisible(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidWhatsapp(whatsapp)) {
      setErrorMessage('Formato inválido. Use (99) 96666-3333');
      return;
    }

    const data = {
      name: '',
      description: message,
      whatsapp: whatsapp,
      from: 'mousetrap-home',
      navigationHistory: [],
    };

    try {
      const response = await fetch('https://nzt18l3ev6.execute-api.us-east-1.amazonaws.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSuccessMessage('Mensagem enviada com sucesso!');
      setErrorMessage('');
      setWhatsapp('');
      setMessage('');
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
      setErrorMessage('Erro ao enviar a mensagem. Por favor, tente novamente.');
    }
  };

  const isValidWhatsapp = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');
    return cleanedNumber.length === 11 && !/^(\d)\1+$/.test(cleanedNumber);
  };

  return (
    <Container>
      <Header>
        <LogoWrapper>
          <Logo src="/logo.png" alt="Oráculo Brasileiro" />
        </LogoWrapper>
        <Title>PARABÉNS!</Title>
        <Title>Você chegou ao Oráculo Brasileiro</Title>
      </Header>
      <Main>
        <VideoWrapper>
          {videoVisible ? (
            <Video src="/video.mp4" controls autoPlay />
          ) : (
            <ThumbnailWrapper onClick={handlePlayButtonClick}>
              <Thumbnail src="/thumbnail.png" alt="Video Thumbnail" />
              <PlayButton>▶</PlayButton>
            </ThumbnailWrapper>
          )}
        </VideoWrapper>
        <Quote>"Sabedoria ancestral para transformar nossa realidade."</Quote>
        <Options>
          <Option to="/faça-parte">FAÇA PARTE DO ORÁCULO</Option>
          <Option to="/aprofundamento">DESCUBRA MAIS SOBRE VOCÊ</Option>
          <Option to="/treinamentos">CURSOS E TREINAMENTOS</Option>
          <Option to="/ebooks">LIVROS DIGITAIS</Option>
        </Options>
        <Sections>
          <CarouselSection>
            <SectionTitle>O universo é mental:</SectionTitle>
            <QuoteCarousel>
              <Carousel
                responsive={responsive}
                autoPlay
                infinite
                autoPlaySpeed={7000}
              >
                <QuoteSlide>
                  <QuoteImage src="/quote1.png" alt="Quote 1" />
                </QuoteSlide>
                <QuoteSlide>
                  <QuoteImage src="/quote2.png" alt="Quote 2" />
                </QuoteSlide>
                <QuoteSlide>
                  <QuoteImage src="/quote3.png" alt="Quote 3" />
                </QuoteSlide>
              </Carousel>
            </QuoteCarousel>
          </CarouselSection>
          <Section>
            <SectionTitle>Os Iniciados</SectionTitle>
            <SectionContent>
              Cuja missão é dissiminar conhecimento para lançar luz sobre a escuridão e com isso exaurir a sombra da ignorância.
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>Toda verdade, são meias verdades:</SectionTitle>
            <VideoGallery>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ybXJamXlKrU?si=GO6nMHG_NXecTYPy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video 1"
              ></iframe>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/FBh4Afe-jYg?si=JcY9ruFRf01TQDUe"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video 2"
              ></iframe>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/srM7iGe5kFQ?si=6G6uvas6qnol9wXh"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video 3"
              ></iframe>
            </VideoGallery>
          </Section>
          <Section>
            <SectionTitle>Contato</SectionTitle>
            <SectionContent>
              Quer dizer algo? Mande uma mensagem e o Oráculo poderá entrar em contato.
            </SectionContent>
            <Form onSubmit={handleSubmit}>
              <Label htmlFor="whatsapp">WhatsApp:</Label>
              <InputMask
                mask="(99) 99999-9999"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                placeholder="(99) 96666-3333"
                required
              >
                {(inputProps) => <Input {...inputProps} type="text" />}
              </InputMask>
              <Label htmlFor="message">Mensagem:</Label>
              <Textarea
                id="message"
                name="message"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <SubmitButton type="submit">Enviar</SubmitButton>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            </Form>
          </Section>
        </Sections>
        <Footer>
          &copy; 2024 Oráculo Brasileiro. Trazendo luz aos mistérios da existência.
        </Footer>
      </Main>
    </Container>
  );
};

export default Home;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #1e1e1e 40%, #3e3e3e 100%);
  color: #f5f5f5;
  min-height: 100vh;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background: #1e1e1e;
  box-shadow: 0 0 20px rgba(106, 13, 173, 0.8), 0 0 40px rgba(106, 13, 173, 0.6), 0 0 60px rgba(106, 13, 173, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px
`;

const Logo = styled.img`
  width: 80%;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #e0e0e0;
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: 0 0 10px #6a0dad;
  text-align: center;
  max-width: 90%;
  word-wrap: break-word;
  line-height: 1.0; // Adjust line height to reduce space between lines
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    font-size: 2.5em; // Larger font size for larger screens
  }

  @media (min-width: 1024px) {
    font-size: 3em; // Even larger font size for even larger screens
  }
`;


const Main = styled.main`
  width: 100%;
  max-width: 1200px;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  border: 2px solid #444;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 0 15px rgba(106, 13, 173, 0.5);
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ThumbnailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PlayButton = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2em;
  transition: background 0.3s ease;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const Quote = styled.p`
  font-size: 1.5em;
  color: #bbb;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.2; // Adjust line height to reduce space between lines
  font-style: italic;
  text-shadow: 0 0 5px #6a0dad;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const Option = styled(Link)`
  background: linear-gradient(90deg, #6a0dad, #4a148c);
  color: #fff;
  padding: 15px 25px;
  margin: 10px 0;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.2em;
  line-height: 1.2; // Adjust line height to reduce space between lines
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 250px;
  text-align: center;
  &:hover {
    background: linear-gradient(90deg, #4a148c, #6a0dad);
    transform: translateY(-5px);
  }
`;

const Sections = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const Section = styled.div`
  margin-bottom: 40px;
  text-align: center;
`;

const CarouselSection = styled(Section)`
  margin-bottom: 60px;
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  color: #e0e0e0;
  text-shadow: 0 0 10px #6a0dad;
  margin-bottom: 20px;
`;

const SectionContent = styled.p`
  font-size: 1.2em;
  color: #bbb;
  margin-bottom: 15px
`;

const QuoteCarousel = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const QuoteSlide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuoteImage = styled.img`
  max-width: 100%;
  border-radius: 10px;
`;

const VideoGallery = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  iframe {
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(106, 13, 173, 0.5);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const Label = styled.label`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #6a0dad;
  border-radius: 10px;
  width: 100%;
  font-size: 1em;
`;

const Textarea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #6a0dad;
  border-radius: 10px;
  width: 100%;
  font-size: 1em;
`;

const SubmitButton = styled.button`
  background: linear-gradient(90deg, #6a0dad, #4a148c);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: linear-gradient(90deg, #4a148c, #6a0dad);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 10px;
`;

const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  font-size: 1em;
  color: #bbb;
  border-top: 1px solid #444;
  margin-top: 40px;
  width: 100%;
`;
