import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const DataView = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('http://localhost:3001/data')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        setData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error.message);
      });
  }, []);

  return (
    <Container>
      <h1>Dados Coletados</h1>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {data.length === 0 && !error ? (
        <p>Nenhum dado coletado ainda.</p>
      ) : (
        <ul>
          {data.map((item, index) => (
            <li key={index}>
              <p><strong>Nome:</strong> {item.name}</p>
              <p><strong>Descrição:</strong> {item.description}</p>
              <p><strong>WhatsApp:</strong> {item.whatsapp}</p>
              <p><strong>De:</strong> {item.from}</p>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
};

export default DataView;

const Container = styled.div`
  padding: 20px;
  background: linear-gradient(135deg, #1e1e1e 40%, #3e3e3e 100%);
  color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;

  h1 {
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      background: #2e2e2e;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
`;
