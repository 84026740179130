import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Aprofundamento = () => {
  return (
    <Container>
      <Header>
        <LogoWrapper>
          <Logo src="/logo.png" alt="Oráculo Brasileiro" />
        </LogoWrapper>
        <Title>O que você deseja?</Title>
      </Header>
      <Main>
        <Options>
          <Option to="/aprofundamento/mentoria">PROGRAMA DE MENTORIA</Option>
          <Option to="/aprofundamento/sonhos">INTERPRETAR SONHOS</Option>
          <Option to="/aprofundamento/desenhos-e-pinturas">INTERPRETAR DESENHOS E PINTURAS</Option>
          <Option to="/aprofundamento/analise-de-perfil">ANÁLISE DO SEU PERFIL</Option>
        </Options>
      </Main>
      <Footer>
        &copy; 2024 Oráculo Brasileiro. Trazendo luz aos mistérios da vida.
      </Footer>
    </Container>
  );
};

export default Aprofundamento;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #1e1e1e 40%, #3e3e3e 100%);
  color: #f5f5f5;
  min-height: 100vh;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background: #1e1e1e;
  box-shadow: 0 0 20px rgba(106, 13, 173, 0.8), 0 0 40px rgba(106, 13, 173, 0.6), 0 0 60px rgba(106, 13, 173, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 80%;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #e0e0e0;
  margin-top: 10px;
  text-shadow: 0 0 10px #6a0dad;
  text-align: center;
  max-width: 90%;
  word-wrap: break-word;
  line-height: 1.0;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    font-size: 2.5em;
  }

  @media (min-width: 1024px) {
    font-size: 3em;
  }
`;

const Main = styled.main`
  width: 100%;
  max-width: 1200px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const Option = styled(Link)`
  background: linear-gradient(90deg, #6a0dad, #4a148c);
  color: #fff;
  padding:10px 15px; // Increased padding for a cozier feel
  margin: 10px 0; // Increased margin for better spacing
  text-decoration: none;
  border-radius: 30px; // More rounded corners
  font-size: 1.3em; // Slightly larger font size
  line-height: 1.4; // Adjusted line height for better readability
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 300px; // Increased width to accommodate longer text
  text-align: center;
  word-wrap: break-word; // Allows text to wrap
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background: linear-gradient(90deg, #4a148c, #6a0dad);
    transform: translateY(-5px);
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  font-size: 1em;
  color: #bbb;
  border-top: 1px solid #444;
  margin-top: auto; // Pushes footer to the bottom of the container
  width: 100%;
`;
