// src/pages/VideoTraining.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import styled from 'styled-components';

const VideoTraining = () => {
  const history = useHistory();

  useEffect(() => {
    // Log the pageview for /video-training
    ReactGA.pageview('/video-training');

    // Redirect to /dashboard after logging the pageview
    const timer = setTimeout(() => {
      history.push('/eu-quero');
    }, 1000); // Delay can be adjusted if necessary

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [history]);

  return (
    <Container>
      <Title>Programas de treinamento</Title>
      {/* Add content for Video Training Programs here */}
    </Container>
  );
};

export default VideoTraining;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
`;
