// src/components/LoggedRedirect.js
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

const LoggedRedirect = ({ to, path }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // Log the pageview for the current path
    ReactGA.pageview(path);

    // Delay the redirection to ensure the pageview is captured
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 1000); // 100 milliseconds delay

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [path]);

  // Only redirect after the delay
  return redirect ? <Redirect to={to} /> : null;
};

export default LoggedRedirect;
