import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import { initGA, logEvent, logPageView } from '../utils/analytics';
import ReactGA from 'react-ga';

initGA(); // Ensure initialization

const CustomerJourney = () => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    logPageView(); // Log initial page view
  }, []);

  useEffect(() => {
    logEvent('CustomerJourney', 'Step', `Step ${step}`);
    ReactGA.event({
      category: 'CustomerJourney',
      action: 'Step',
      label: `Step ${step}`
    });
    console.log(`Logged step event: Step ${step}`); // Debug log

    // Track navigation history
    const currentHistory = JSON.parse(localStorage.getItem('navigationHistory')) || [];
    currentHistory.push(location.pathname);
    localStorage.setItem('navigationHistory', JSON.stringify(currentHistory));
  }, [step, location.pathname]);

  const saveData = async (data) => {
    localStorage.setItem('userData', JSON.stringify(data));
  
    try {
      const response = await fetch('https://nzt18l3ev6.execute-api.us-east-1.amazonaws.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log('Data saved successfully:', responseData);
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };
  

  const handleNextStep = () => {
    if (step === 1 && name.trim() === '') {
      setErrorMessage('Por favor, insira seu nome.');
      return;
    }
    if (step === 2 && description.trim() === '') {
      setErrorMessage('Por favor, conte-nos um pouco mais.');
      return;
    }
    if (step === 3 && !isValidWhatsapp(whatsapp)) {
      setErrorMessage('Formato inválido. Use (99) 96666-3333');
      return;
    }

    const data = {
      name: step >= 1 ? name : null,
      description: step >= 2 ? description : null,
      whatsapp: step >= 3 ? whatsapp : null,
      from: history.location.pathname,
      navigationHistory: JSON.parse(localStorage.getItem('navigationHistory')) || []
    };
    saveData(data);

    setStep(step + 1);
    setIsValid(true);
    setErrorMessage('');
    ReactGA.event({
      category: 'CustomerJourney',
      action: 'NextStep',
      label: `Step ${step} completed`
    });
    logEvent('CustomerJourney', 'NextStep', `Step ${step} completed`);
    console.log('Event sent:', `Step ${step} completed`); // Debug log
  };

  const isValidWhatsapp = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');
    return cleanedNumber.length === 11 && !/^(\d)\1+$/.test(cleanedNumber);
  };

  const handleSubmit = async () => {
    if (!isValidWhatsapp(whatsapp)) {
      setIsValid(false);
      setErrorMessage('Formato inválido. Use (99) 96666-3333');
      return;
    }
    const data = { 
      name, 
      description, 
      whatsapp, 
      from: history.location.pathname,
      navigationHistory: JSON.parse(localStorage.getItem('navigationHistory')) || [] 
    };
    localStorage.setItem('userData', JSON.stringify(data));

    try {
      const response = await fetch('https://nzt18l3ev6.execute-api.us-east-1.amazonaws.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      logEvent('CustomerJourney', 'Submit', 'Form Submitted');
      ReactGA.event({
        category: 'CustomerJourney',
        action: 'Submit',
        label: 'Form Submitted'
      });
      console.log('Event sent: Form Submitted'); // Debug log
      setTimeout(() => {
        setStep(4);
      }, 1000);
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  const handleReturn = () => {
    history.push('/');
  };

  return (
    <Container>
      <Header>
        <LogoWrapper>
          <Logo src="/logo.png" alt="Oráculo Brasileiro" />
        </LogoWrapper>
      </Header>
      {step === 1 && (
        <Step>
          <Label htmlFor="name">Qual é o seu nome?</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <NextButton onClick={handleNextStep}>Próximo</NextButton>
        </Step>
      )}
      {step === 2 && (
        <Step>
          <Label htmlFor="description">Para prosseguir, conte um pouco melhor o que deseja:</Label>
          <Textarea
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
            required
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <NextButton onClick={handleNextStep}>Próximo</NextButton>
        </Step>
      )}
      {step === 3 && (
        <Step>
          <Label htmlFor="whatsapp">Qual é o seu WhatsApp?</Label>
          <InputMask
            mask="(99) 99999-9999"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            placeholder="(99) 96666-3333"
            required
          >
            {(inputProps) => <Input {...inputProps} type="text" />}
          </InputMask>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <NextButton onClick={handleSubmit}>Entrar</NextButton>
        </Step>
      )}
      {step === 4 && (
        <Step>
          <AnimatedMessage>
            <SorryMessage>
              Sinto muito, {name}. Ainda não chegou o momento.
              <br />
              Entraremos em contato em breve.
            </SorryMessage>
          </AnimatedMessage>
          <AnimatedButton onClick={handleReturn}>Retornar</AnimatedButton>
        </Step>
      )}
      <Footer>
        &copy; 2024 Oráculo Brasileiro. Trazendo luz aos mistérios da existência.
      </Footer>
    </Container>
  );
};

export default CustomerJourney;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const buttonFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #1e1e1e 40%, #3e3e3e 100%);
  color: #f5f5f5;
  min-height: 100vh;
  justify-content: center;
  animation: ${fadeIn} 1s ease-in-out;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background: #1e1e1e;
  box-shadow: 0 0 20px rgba(106, 13, 173, 0.8), 0 0 40px rgba(106, 13, 173, 0.6), 0 0 60px rgba(106, 13, 173, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 80%;
  height: auto;
  border-radius: 50%;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  animation: ${fadeIn} 1s ease-in-out;
`;

const Label = styled.label`
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #e0e0e0;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #6a0dad;
  border-radius: 10px;
  width: 100%;
  font-size: 1em;
`;

const Textarea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #6a0dad;
  border-radius: 10px;
  width: 100%;
  font-size: 1em;
`;

const NextButton = styled.button`
  background: linear-gradient(90deg, #6a0dad, #4a148c);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: linear-gradient(90deg, #4a148c, #6a0dad);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 20px;
  font-size: 0.9em;
`;

const AnimatedMessage = styled.div`
  margin-bottom: 20px;
  animation: ${fadeIn} 2s ease-in-out;
`;

const SorryMessage = styled.h2`
  font-size: 2em;
  color: #e0e0e0;
  text-align: center;
  margin-top: 20px;
  text-shadow: 0 0 10px #6a0dad;
`;

const AnimatedButton = styled.button`
  background: linear-gradient(90deg, #6a0dad, #4a148c);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  animation: ${buttonFadeIn} 3s ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
  &:hover {
    background: linear-gradient(90deg, #4a148c, #6a0dad);
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  font-size: 1em;
  color: #bbb;
  border-top: 1px solid #444;
  margin-top: 40px;
  width: 100%;
`;
