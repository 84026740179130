import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Community = () => {
  return (
    <Container>
      <Header>
        <LogoWrapper>
          <Logo src="/logo.png" alt="Oráculo Brasileiro" />
        </LogoWrapper>
        <Title>Selecione um assunto de seu interesse:</Title>
      </Header>
      <Main>
        <Options>
          <Option to="/faça-parte/voluntario">SEJA VOLUNTÁRIO</Option>
          <Option to="/faça-parte/iniciacao">PROGRAMA DE INICIAÇÃO</Option>
          <Option to="/faça-parte/doe">SEJA DOADOR</Option>
        </Options>
      </Main>
      <Footer>
        &copy; 2024 Oráculo Brasileiro. Trazendo luz aos mistérios da vida.
      </Footer>
    </Container>
  );
};

export default Community;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #1e1e1e 40%, #3e3e3e 100%);
  color: #f5f5f5;
  min-height: 100vh;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background: #1e1e1e;
  box-shadow: 0 0 20px rgba(106, 13, 173, 0.8), 0 0 40px rgba(106, 13, 173, 0.6), 0 0 60px rgba(106, 13, 173, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 80%;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #e0e0e0;
  margin-top: 10px;
  text-shadow: 0 0 10px #6a0dad;
  text-align: center;
  max-width: 90%;
  word-wrap: break-word;
  line-height: 1.0;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    font-size: 2.5em;
  }

  @media (min-width: 1024px) {
    font-size: 3em;
  }
`;

const Main = styled.main`
  width: 100%;
  max-width: 1200px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const Option = styled(Link)`
  background: linear-gradient(90deg, #6a0dad, #4a148c);
  color: #fff;
  padding: 15px 25px;
  margin: 10px 0;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.2em;
  line-height: 1.2;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 250px;
  text-align: center;
  &:hover {
    background: linear-gradient(90deg, #4a148c, #6a0dad);
    transform: translateY(-5px);
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  font-size: 1em;
  color: #bbb;
  border-top: 1px solid #444;
  margin-top: auto; // Pushes footer to the bottom of the container
  width: 100%;
`;
